<template>
  <div class="topNav">
    <img src="../../assets/images/home/Frame.svg" alt="">
    <div>
      <a-button type="primary" shape="round" @click="$router.push('/register')">Sign up</a-button>
    </div>
  </div>
  <div class="change-pwd">
    <div class="change-title">Change Password</div>
    <div class="steps">
      <a-steps type="navigation" :current="current">
        <a-step>Verify Email Code</a-step>
        <a-step>Change Password</a-step>
        <a-step>Result</a-step>
      </a-steps>
      <div class="steps-cont" v-if="current === 1">
        <a-form :model="formCode" layout="vertical" ref="firstForm">
          <a-row>
            <a-col :span="14">
              <a-form-item style="margin-right: 40px" field="email" label="User ID" :rules="resetRules.email"
                class="email-input">
                <a-input autocomplete="off" v-model="formCode.email" />
              </a-form-item>
            </a-col>
            <a-col :span="10">
              <a-form-item>
                <div class="code-btn">
                  <a-button type="primary" :disabled="codFlag" @click="getCode" :loading="codeLoading">Get Email Code
                  </a-button>
                  <div class="second" :style="{ display: dispalyCode }">{{ codeTime }}s</div>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item field="email_code" label="Email Code" :rules="[
            { validator: validataCode }]">
            <a-input v-model="formCode.email_code" autocomplete="off" />
          </a-form-item>
          <a-form-item>
            <div class="next-btn">
              <a-button type="primary" shape="round" @click="firstNext" :loading="firstLoading">Next</a-button>
            </div>
          </a-form-item>
        </a-form>
      </div>
      <div class="steps-cont" v-if="current === 2">
        <a-form :model="formpassword" layout="vertical" ref="secondForm">
          <a-form-item field="password" label="New Password" :rules="[
            { validator: validatePassword }
          ]">
            <a-popover position="right" title="Format">
              <a-input-password autocomplete="new-password" v-model="formpassword.password" allow-clear />
              <template #content>
                <div v-show="letterStatus === false">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#1D2129" />
                  </svg>
                  Uppercase and Lowercase Letters
                </div>
                <div v-show="letterStatus === true" style="color:#00B42A;">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#00B42A" />
                  </svg>
                  Uppercase and Lowercase Letters
                </div>
                <div v-show="numStatus === false">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#1D2129" />
                  </svg>
                  Numeric Character
                </div>
                <div v-show="numStatus === true" style="color:#00B42A;">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#00B42A" />
                  </svg>
                  Numeric Character
                </div>
                <div v-show="other === false">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#1D2129" />
                  </svg>
                  Special Character
                </div>
                <div v-show="other === true" style="color:#00B42A;">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#00B42A" />
                  </svg>
                  Special Character
                </div>
                <div v-show="length === false">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#1D2129" />
                  </svg>
                  At Least Eight Characters
                </div>
                <div v-show="length === true" style="color:#00B42A;">
                  <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 5.27C4.914 5.27 6.076 4.108 6.076 2.68C6.076 1.266 4.914 0.104 3.5 0.104C2.072 0.104 0.91 1.266 0.91 2.68C0.91 4.108 2.072 5.27 3.5 5.27ZM3.5 4.71C2.366 4.71 1.47 3.8 1.47 2.68C1.47 1.574 2.366 0.663999 3.5 0.663999C4.606 0.663999 5.516 1.574 5.516 2.68C5.516 3.8 4.606 4.71 3.5 4.71Z"
                      fill="#00B42A" />
                  </svg>
                  At Least Eight Characters
                </div>
              </template>
            </a-popover>
          </a-form-item>
          <a-form-item field="password_confirmation" label="Comfirm Password" :rules="[
            { validator: validateConfirm }
          ]" :validate-status="confirmStatus">
            <a-input-password v-model="formpassword.password_confirmation" autocomplete="new-password" allow-clear />
          </a-form-item>
          <a-form-item>
            <div class="next-btn">
              <a-button type="primary" shape="round" @click="secondNext" :loading="secondLoading">Reset Password
              </a-button>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { Message } from '@arco-design/web-vue'
import { useRouter } from 'vue-router'
// 引入api中的接口
import { getcode, reset, verifyEmailCode } from '@/api/account.js'
const router = useRouter()
const dispalyCode = ref('none')
const codeTime = ref(30)
const current = ref(1)
const formCode = reactive({
  email: '',
  email_code: ''
})
const codFlag = ref(false)
// 验证邮箱
const resetRules = {
  email: [
    {
      validator: (value, callback) => {
        const emailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
        if (!value) {
          callback(new Error('Please enter.').message)
          // codFlag.value = true
        } else if (!emailReg.test(value)) {
          callback(new Error('Please enter correct email format.').message)
          // codFlag.value = true
        } else {
          callback()
          // codFlag.value = false
        }
      }
    }
  ]
}
// 验证Email Code
function validataCode (value, callback) {
  if (!value) {
    callback(new Error('Please enter email code.').message)
  } else {
    callback()
  }
}
// 获取验证码
const codeLoading = ref(false)
async function getCode () {
  firstForm.value.validateField('email', async (value) => {
    if (!value) {
      codeLoading.value = true
      const msg = await getcode({
        type: 'resetPass',
        email: formCode.email
      })
      if (msg.code === 0) {
        codeLoading.value = false
        Message.success('Email code send success.')
      } else {
        codeLoading.value = false
        Message.error(msg.message)
        return
      }
      codFlag.value = true
      dispalyCode.value = 'block'
      const timeId = setInterval(() => {
        let timeCode = codeTime.value
        timeCode--
        codeTime.value = timeCode
        if (codeTime.value < 1) {
          clearInterval(timeId)
          codFlag.value = false
          codeTime.value = 30
          dispalyCode.value = 'none'
        }
      }, 1000)
    }
  })
}
const firstForm = ref(null)
const firstLoading = ref(false)
const firstNext = () => {
  firstForm.value.validate(async (value) => {
    if (!value) {
      firstLoading.value = true
      const msg = await verifyEmailCode({
        email: formCode.email,
        email_code: formCode.email_code
      })
      if (msg.code === 0) {
        firstLoading.value = false
        current.value = 2
      } else {
        firstLoading.value = false
        Message.error(msg.message)
      }
    }
  })
}
const secondForm = ref(null)
const formpassword = reactive({
  password: '',
  password_confirmation: ''
})
const confirmStatus = ref('')
// 验证确认密码
function validateConfirm (value, callback) {
  if (!value) {
    callback(new Error('Enter your new password again.').message)
  } else if (value !== formpassword.password) {
    callback(new Error('Passwords not match.').message)
  } else {
    callback()
    confirmStatus.value = 'success'
  }
}
const letterStatus = ref(false)
const numStatus = ref(false)
const other = ref(false)
const length = ref(false)
// 验证密码
function validatePassword (value, callback) {
  let upperCase = (/^.*[A-Z]+.*$/).test(value)
  let lowerCase = (/^.*[a-z]+.*$/).test(value)
  let hasNumber = (/^.*[0-9]+.*$/).test(value)
  let hasOther = (/^.*[^0-9A-Za-z]+.*$/).test(value)
  if (!value) {
    callback(new Error('Password is required.').message)
  } else {
    if (!upperCase || !lowerCase) {
      callback(new Error('Uppercase and Lowercase Letters.').message)
      letterStatus.value = false
    } else {
      letterStatus.value = true
    }
    if (!hasNumber) {
      callback(new Error('Numeric Character.').message)
      numStatus.value = false
    } else {
      numStatus.value = true
    }
    if (!hasOther) {
      callback(new Error('Special Character.').message)
      other.value = false
    } else {
      other.value = true
    }
    if (value.length < 8) {
      callback(new Error('At least Eight Characters.').message)
      length.value = false
    } else {
      length.value = true
    }
  }
}
const secondLoading = ref(false)
const secondNext = () => {
  secondForm.value.validate(async (value) => {
    if (!value) {
      secondLoading.value = true
      const msg = await reset({
        email: formCode.email,
        email_code: formCode.email_code,
        password: formpassword.password,
        password_confirmation: formpassword.password_confirmation
      })
      if (msg.code === 0) {
        secondLoading.value = false
        router.push('/successreset')
      } else {
        secondLoading.value = false
        Message.error(msg.message)
      }
    }
  })
}
</script>

<style lang="less" scoped>
.topNav {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 0 82px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.change-pwd {
  margin-top: 66px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .change-title {
    color: var(--color-text-1);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .steps {
    margin-top: 48px;
    width: 840px;

    .steps-cont {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 48px;

      .arco-form {
        width: 357px;
      }
    }
  }
}

.next-btn {
  text-align: center;
  width: 100%;
}

:deep(.arco-col-10 .arco-form-item) {
  margin-bottom: 0;
}

.code-btn {
  margin-top: 22px;
  width: 100%;
  position: relative;

  .arco-btn {
    width: 100%;
    border-radius: 0 12px 12px 0;
  }
}

.email-input .arco-input-wrapper {
  border-radius: 12px 0 0 12px;
}

.second {
  color: var(--color-text-4);
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  position: absolute;
  right: -40px;
  top: 7px;
}
</style>
